import React, { useEffect, useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomChart(props) {
  const { lineChartData, selectedValue } = props;
  const totalNumData = lineChartData.length;
  let xAxisTime = [
    lineChartData[0]["jsTime"],
    lineChartData[Math.ceil(totalNumData / 6)]["jsTime"],
    lineChartData[Math.ceil((2 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((3 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((4 * totalNumData) / 6)]["jsTime"],
    lineChartData[Math.ceil((5 * totalNumData) / 6)]["jsTime"],
    lineChartData[totalNumData - 1]["jsTime"],
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 55,
          left: 45,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="jsTime" ticks={xAxisTime} />
        <YAxis
          dataKey={selectedValue}
          unit={"kW"}
          domain={[0, Math.max(...lineChartData.map((o) => o[selectedValue]))]}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey={selectedValue}
          stroke="#8884d8"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
