import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Slider from "@material-ui/core/Slider";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";

import "rc-color-picker/assets/index.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "react-datepicker/dist/react-datepicker.css";

import { addOffer } from "../../../actions/offer";

import Widget from "../../../components/Widget/Widget";
import s from "./Elements.module.scss";
import periods from "./periods";
import { moreThanTwoDecimalPlace } from "./Helpers";
import OfferModal from "./OfferComponents/OfferModal";

export default function Elements() {
  const state = {
    selectFacilities: [
      { value: 1, label: "IL : CLEAR : CRYSTAL", max_mw: 5 },
      { value: 2, label: "IL_CRYS : ALJN : PULSE1", max_mw: 0.1 },
    ],
    selectPeriods: periods.periods,
  };
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [offerType, setOfferType] = useState("variation");
  const [offerDate, setOfferDate] = useState(new Date());
  const [facility, setFacility] = useState(-1);
  const [maxMW, setMaxMW] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);

  const [dateHasError, setDateHasError] = useState(false);
  const [facilityHasError, setFacilityHasError] = useState(false);
  const [fromPeriodHasError, setFromPeriodHasError] = useState(false);
  const [toPeriodHasError, setToPeriodHasError] = useState(false);
  const [priceQuantityError, setPriceQuantityError] = useState(false);

  function toggleOfferModal() {
    setOfferModalIsOpen(!offerModalIsOpen);
  }
  const [periodPairs, setPeriodPairs] = useState([
    {
      periodFrom: 1,
      periodTo: 2,
      qp: [
        { q: 0, p: 0, s: 0, error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
        { q: 0, p: 0, s: [0, 0], error: null },
      ],
    },
  ]);
  const [fromPeriodOptions, setFromPeriodOptions] = useState(periods.periods);
  const [toPeriodOptions, setToPeriodOptions] = useState([periods.periods[0]]);

  // if toPeriod is after - just update
  // if toPeriod is before - update fromPeriod to value,  update toPeriod to 0
  const updatePeriodFrom = (data, index) => {
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[index].periodFrom = data.value;
      if (data.value >= newPeriodPairs[index].periodTo || data.value === 0) {
        if (data.value === 0) {
          setToPeriodOptions(fromPeriodOptions);
        } else {
          setToPeriodOptions(
            periods.periods
              .slice(0, 1)
              .concat(periods.periods.slice(data.value))
          );
        }
        updatePeriodTo(periods.periods[0], 0);
      }
      return newPeriodPairs;
    });
  };

  const updatePeriodTo = (data, index) => {
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[index].periodTo = data.value;
      return newPeriodPairs;
    });
  };

  const handleChangeForSlider = useCallback(
    (newValue, periodIndex, qpIndex) => {
      setPeriodPairs((prevState) => {
        const newPeriodPairs = [...prevState];
        newPeriodPairs[periodIndex].qp[qpIndex] = {
          ...newPeriodPairs[periodIndex].qp[qpIndex],
          s: newValue,
          q:
            qpIndex === 0
              ? Math.floor(((maxMW * newValue) / 100) * 10) / 10
              : Math.floor(((maxMW * (newValue[1] - newValue[0])) / 100) * 10) /
                10,
        };

        for (let index = qpIndex + 1; index <= 4; index++) {
          newPeriodPairs[periodIndex].qp[index] = {
            ...newPeriodPairs[periodIndex].qp[index],
            s:
              newValue.length > 1
                ? [newValue[1], newValue[1]]
                : [newValue, newValue],
          };
        }

        return newPeriodPairs;
      });
    },
    [maxMW]
  );

  const handleChangeForPrice = useCallback((newValue, periodIndex, qpIndex) => {
    if (moreThanTwoDecimalPlace(newValue)) return;
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      newPeriodPairs[periodIndex].qp[qpIndex] = {
        ...newPeriodPairs[periodIndex].qp[qpIndex],
        p: newValue,
      };
      return newPeriodPairs;
    });
  }, []);

  const handleAddOffer = async () => {
    let hasError = false;
    setPeriodPairs((prevState) => {
      const newPeriodPairs = [...prevState];
      let previousPrice = 0;
      for (let index = 0; index < newPeriodPairs[0].qp.length; index++) {
        const currentElement = newPeriodPairs[0].qp[index];
        if (index === 0) {
          previousPrice = currentElement.p;
        } else {
          if (
            currentElement.p !== 0 &&
            parseFloat(previousPrice) >= parseFloat(currentElement.p)
          ) {
            // has error
            newPeriodPairs[0].qp[index] = {
              ...newPeriodPairs[0].qp[index],
              error: "This price must be higher than the previous price.",
            };
            hasError = true;
          } else {
            previousPrice = currentElement.p;
          }
        }
      }

      // go ahead to submit only if there is no error
      if (!hasError) {
        setLoading(true);
        const obj = {
          offerType,
          offerDate: moment(offerDate).format("DD/MM/YYYY"),
          facility,
          periodPairs,
        };

        dispatch(addOffer(obj));
      }
      return newPeriodPairs;
    });
  };

  const getAriaValueText = useCallback(
    (periodIndex, pair_index) => {
      let quantity = periodPairs[periodIndex].qp[pair_index].q;
      return `${quantity}MW`;
    },
    [periodPairs]
  );

  const renderQuantityPricePairs = useCallback(
    (qpArray, periodIndex) => {
      return qpArray.map((pair, pair_index) => {
        return (
          <div key={`${periodIndex}_${pair_index}`}>
            <FormGroup row>
              <Label md="2" for="default-select">
                Quantity {pair_index + 1} (
                {getAriaValueText(periodIndex, pair_index)})
              </Label>
              <Col md="4" className={s.select}>
                <Slider
                  defaultValue={
                    pair_index === 0
                      ? 0
                      : periodPairs[periodIndex].qp[pair_index].s
                  }
                  onChange={(_, newValue) =>
                    handleChangeForSlider(newValue, periodIndex, pair_index)
                  }
                  valueLabelDisplay="auto"
                  value={periodPairs[periodIndex].qp[pair_index].s}
                  aria-labelledby="range-slider"
                  step={1}
                />
              </Col>
              <Label md={2} for="normal-field">
                Price {pair_index + 1}
              </Label>
              <Col md={4}>
                <Input
                  type="number"
                  id="normal-field"
                  placeholder={0.0}
                  min={0}
                  step={0.01}
                  onChange={(event) =>
                    handleChangeForPrice(
                      event.target.value,
                      periodIndex,
                      pair_index
                    )
                  }
                  value={periodPairs[periodIndex].qp[pair_index].p}
                />
                {periodPairs[periodIndex].qp[pair_index].error ? (
                  <div className={"help-block text-danger label"}>
                    {periodPairs[periodIndex].qp[pair_index].error}
                  </div>
                ) : null}
              </Col>
            </FormGroup>
          </div>
        );
      });
    },
    [getAriaValueText, handleChangeForPrice, handleChangeForSlider, periodPairs]
  );

  function handleOnSubmit() {
    setLoading(true);

    // check if date is less than today's date
    if (moment(offerDate).isBefore(moment().startOf("day"))) {
      setDateHasError(true);
    }
    // check if facility is selected
    if (facility < 0) {
      setFacilityHasError(true);
    }
    // check if period has error
    let numberOfPeriodsInMinutes = periodPairs[0].periodFrom * 30;
    let fromPeriodConvertedToTime = moment()
      .startOf("day")
      .add(numberOfPeriodsInMinutes, "m")
      .add(65, "m");
    if (
      (fromPeriodConvertedToTime.isBefore(moment()) &&
        moment(offerDate).isSame(moment(), "day")) ||
      periodPairs[0].periodFrom === 0
    ) {
      setFromPeriodHasError(true);
    }
    if (
      periodPairs[0].periodFrom > periodPairs[0].periodTo ||
      periodPairs[0].periodTo === 0
    ) {
      setToPeriodHasError(true);
    }
    // check if offered 0 quantity
    if (periodPairs[0].qp[0].q <= 0) {
      setPriceQuantityError(true);
    }
    setLoading(false);
    toggleOfferModal();
    return;
  }

  useEffect(() => {
    setPeriodPairs([
      {
        periodFrom: 0,
        periodTo: 0,
        qp: [
          { q: 0, p: 0, s: 0, error: null },
          { q: 0, p: 0, s: [0, 0], error: null },
          { q: 0, p: 0, s: [0, 0], error: null },
          { q: 0, p: 0, s: [0, 0], error: null },
          { q: 0, p: 0, s: [0, 0], error: null },
        ],
      },
    ]);

    if (moment(offerDate).isSame(moment(), "day")) {
      let firstAvailableOfferTime = moment().add(65, "m");
      let firstPeriod = 1;
      while (firstAvailableOfferTime.isAfter(moment().startOf("day"))) {
        firstAvailableOfferTime.subtract(30, "m");
        firstPeriod++;
      }
      setFromPeriodOptions(
        periods.periods.slice(0, 1).concat(periods.periods.slice(firstPeriod))
      );
      setToPeriodOptions(
        periods.periods.slice(0, 1).concat(periods.periods.slice(firstPeriod))
      );
    } else if (moment(offerDate).isAfter(moment(), "day")) {
      setFromPeriodOptions(periods.periods);
      setToPeriodOptions(periods.periods);
    }
  }, [facility, maxMW, offerDate]);

  return (
    <div>
      <Form onSubmit={handleSubmit(handleAddOffer)}>
        <Row>
          <Col>
            <Row className="gutter mb-4">
              <Col xs={12} md={8}>
                <Widget className="widget-p-md">
                  <div className="headline-2 mb-3">Add Offer</div>
                  <Row>
                    <Col md="6">
                      <div className="mb-2">Submission Type</div>
                      <FormGroup className="radio abc-radio">
                        <Input
                          type="radio"
                          id="offer_type_variation"
                          value="variation"
                          name="offerType"
                          onChange={(e) => setOfferType(e.target.value)}
                          checked={offerType === "variation"}
                        />
                        <Label for="offer_type_variation">Variation</Label>
                      </FormGroup>
                      {/* <FormGroup className="radio abc-radio">
                        <Input
                          type="radio"
                          id="offer_type_standing"
                          value="standing"
                          name="offerType"
                          onChange={(e) => setOfferType(e.target.value)}
                          checked={offerType === "standing"}
                        />
                        <Label for="offer_type_standing">Standing</Label>
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <FormGroup row>
                        <Label md="4">Offer Date:</Label>
                        <Col md="9">
                          <DatePicker
                            dateformat="dd/MM/yyyy"
                            selected={offerDate}
                            onChange={(date) => setOfferDate(date)}
                            placeholderText="Please select a date!"
                            onFocus={() => {
                              setDateHasError(false);
                            }}
                            minDate={new Date()}
                          />
                          {dateHasError ? (
                            <div className={"help-block text-danger label"}>
                              Offer Date cannot be before current Date
                            </div>
                          ) : null}
                        </Col>
                        <Label md="4">Facility:</Label>
                        <Col md="9">
                          <Select
                            options={state.selectFacilities}
                            onChange={(data) => {
                              setFacility(data.value);
                              setMaxMW(data.max_mw);
                            }}
                          />
                          {facilityHasError ? (
                            <div className={"help-block text-danger label"}>
                              Please select facility
                            </div>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Widget>
              </Col>
            </Row>
            {maxMW > 0 && facility && offerDate && offerType
              ? periodPairs.map((periodPair, index) => {
                  return (
                    <Row className="gutter mb-4" key={index}>
                      <Col xs={12} md={12} className="mt-4 mt-md-0">
                        <Widget className="widget-p-md">
                          <div className="headline-2 mb-4 mt-4">
                            Total Load: {maxMW}MW
                          </div>
                          <div className="headline-2">Period</div>
                          <FormGroup row>
                            <Label md="2" for="default-select">
                              From Period:
                            </Label>

                            <Col md="4" className={s.select}>
                              <Select
                                options={fromPeriodOptions}
                                defaultValue={state.selectPeriods[0]}
                                onChange={(data) =>
                                  updatePeriodFrom(data, index)
                                }
                                value={periods.periods[periodPair.periodFrom]}
                                onFocus={() => {
                                  setFromPeriodHasError(false);
                                }}
                              />
                              {fromPeriodHasError ? (
                                <div className={"help-block text-danger label"}>
                                  From Period Error
                                </div>
                              ) : null}
                            </Col>
                            <Label md="2" for="default-select">
                              To Period:
                            </Label>
                            <Col md="4" className={s.select}>
                              <Select
                                options={toPeriodOptions}
                                defaultValue={state.selectPeriods[0]}
                                onChange={(data) => updatePeriodTo(data, index)}
                                value={periods.periods[periodPair.periodTo]}
                              />{" "}
                              {toPeriodHasError ? (
                                <div className={"help-block text-danger label"}>
                                  To Period Error
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                          {renderQuantityPricePairs(periodPair.qp, index)}
                        </Widget>
                      </Col>
                    </Row>
                  );
                })
              : null}
            {maxMW > 0 &&
            facility &&
            periodPairs[0]["qp"][0]["q"] > 0 &&
            periodPairs[0].periodFrom > 0 &&
            periodPairs[0].periodTo > 0 ? (
              <Row className="gutter mb-4">
                <Col xs={12} md={6} className="mt-4 mt-md-0">
                  <Button
                    color="primary"
                    type="button"
                    className="mr-3 mt-3"
                    onClick={handleOnSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="mr-3 mt-1" />
                    ) : null}
                    Submit
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
        <Button id="hiddenSubmitOfferButton" hidden type="submit" />
        <OfferModal
          offerModalIsOpen={offerModalIsOpen}
          toggleOfferModal={toggleOfferModal}
          periodPairs={periodPairs}
          offerType={offerType}
          offerDate={offerDate}
          loading={loading}
          setLoading={setLoading}
          facility={
            facility > 0
              ? state.selectFacilities[facility - 1].label
              : "Facility not Selected"
          }
        />
      </Form>
    </div>
  );
}
