import React from "react";
import Widget from "../../components/Widget/Widget.js";
import Timeline from "./components/MonitoringComponents/Timeline.js";
import Table from "./components/MonitoringComponents/BatteryTable.js";
import ChargeSettings from "./components/MonitoringComponents/ChargeSettings.js";
import ChartWidget from "./components/MonitoringComponents/ChartWidget.js";
import styles from "./Monitoring.module.scss";
import PowerConsumptionWidget from "./components/MonitoringComponents/PowerConsumptionWidget.js";
import { useSelector } from "react-redux";
import ErrorsTimeline from "./components/MonitoringComponents/ErrorsTimeline.js";

function Monitoring() {
  const store = useSelector((state) => state.auth);
  return (
    <div>
      <div className={styles.WidgetContainer}>
        <Widget title="Live Power Consumption">
          <ChartWidget />
        </Widget>
      </div>
      {/* Power Consumption Table */}
      <div className={styles.WidgetContainer}>
        <Widget
          title="Power Consumption Table "
          className={styles.PowerConsumptionTableContainer}
        >
          <PowerConsumptionWidget />
        </Widget>
      </div>
      {/* Charge Settings*/}
      {store?.currentUser?.role === "admin" ? (
        <div className={styles.WidgetContainer}>
          <Widget title="Charge Settings">
            <ChargeSettings />
          </Widget>
        </div>
      ) : null}
      {/* Table */}
      <div className={styles.WidgetContainer}>
        <Widget title="Battery Data" className={styles.BatteryTableContainer}>
          <Table />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        {/* Action Timeline */}
        <Widget title="Actions" className={styles.TimelineTableContainer}>
          <Timeline />
        </Widget>
      </div>
      <div className={styles.WidgetContainer}>
        {/* Error Timeline */}
        <Widget title="Errors" className={styles.TimelineTableContainer}>
          <ErrorsTimeline />
        </Widget>
      </div>
    </div>
  );
}

export default Monitoring;
